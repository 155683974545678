import { defineStore } from "pinia";
import Cookies from "js-cookie";
import bent from "bent";
export const useCsrfStore = defineStore({
    id: "csrf",
    actions: {
        async initCsrfToken() {
            return await this.fetch("GET", 204, "string")("sanctum/csrf-cookie");
        },
        fetch(method = "GET", expectedStatus = 200, expectedType = "json", shouldCache = true) {
            return bent(`${window.location.protocol}//${window.location.host}/api/`, method, expectedType, expectedStatus, {
                Accept: expectedType === "json" ? "application/json" : "*/*",
                "Cache-Control": shouldCache ? "max=age=90" : "no-cache",
                "X-XSRF-Token": Cookies.get("XSRF-TOKEN"),
                "X-Requested-With": "XMLHttpRequest",
            });
        },
    },
});
