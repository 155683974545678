import { createRouter, createWebHistory } from "vue-router";
const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: "/",
            component: () => import("@/components/CrmContainer.vue"),
            children: [
                {
                    path: "",
                    name: "dashboard.view",
                    component: () => import("@/views/Dashboard/View.vue"),
                },
                {
                    path: "bookings",
                    name: "bookings.list",
                    component: () => import("@/views/ListBookings/View.vue"),
                },
                {
                    path: "bookings/:id",
                    name: "bookings.view",
                    component: () => import("@/views/ViewBooking/View.vue"),
                },
                {
                    path: "customers",
                    name: "customers.list",
                    component: () => import("@/views/ListCustomers/View.vue"),
                },
                {
                    path: "employees",
                    name: "employees.list",
                    component: () => import("@/views/ListEmployees/View.vue"),
                },
                {
                    path: "employees/:id",
                    name: "employees.view",
                    component: () => import("@/views/ViewEmployee/View.vue"),
                },
                {
                    path: "locations",
                    name: "locations.list",
                    component: () => import("@/views/ListLocations/View.vue"),
                },
                {
                    path: "locations/:id",
                    name: "locations.view",
                    component: () => import("@/views/ViewLocation/View.vue"),
                },
                {
                    path: "reportings",
                    name: "reportings.list",
                    component: () => import("@/views/ListReportings/View.vue"),
                },
            ],
        },
        {
            name: "auth.login",
            path: "/login",
            component: () => import("@/views/Auth/Authentication.vue"),
        },
        {
            name: "auth.two-factor",
            path: "/login/two-factor",
            component: () => import("@/views/Auth/TwoFactor.vue"),
        },
        {
            name: "auth.logout",
            path: "/logout",
            component: () => import("@/views/Auth/Logout.vue"),
        },
        {
            name: "appointment.booking",
            path: "/new",
            component: () => import("@/views/BookAppointment/AppointmentBooking.vue"),
        },
        {
            name: "appointment.booking.private",
            path: "/new/:privateid",
            component: () => import("@/views/BookAppointment/AppointmentBooking.vue"),
        },
        {
            name: "appointment.manage",
            path: "/manage/:id",
            //component: () => import("@/views/ManageAppointment/View.vue"),
            component: () => import("@/views/BookAppointment/AppointmentBooking.vue"),
        },
        {
            name: "appointment.suggest",
            path: "/suggest",
            component: () => import("@/views/BookAppointment/LocationSuggestion.vue"),
        },
    ],
});
export default router;
