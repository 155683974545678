import "./bootstrap";
import { createApp } from "vue";
import { createPinia } from "pinia";
import vClickOutside from "click-outside-vue3";
import { flare } from "@flareapp/flare-client";
import { flareVue } from "@flareapp/flare-vue";
import VueGoogleHeatmap from 'vue-google-heatmap';
import App from "./App.vue";
import router from "./router";
import "@/assets/base.css";
/** START Tailwind Dark Mode **/
// On page load or when changing themes, best to add inline in `head` to avoid FOUC
if (localStorage.theme === "dark" ||
    (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)) {
    document.documentElement.classList.add("dark");
}
else {
    document.documentElement.classList.remove("dark");
}
// Whenever the user explicitly chooses light mode
localStorage.theme = "light";
// Whenever the user explicitly chooses dark mode
localStorage.theme = "dark";
// Whenever the user explicitly chooses to respect the OS preference
localStorage.removeItem("theme");
/** END Tailwind Dark Mode **/
const app = createApp(App);
app.use(createPinia());
app.use(router);
app.use(vClickOutside);
app.provide('impress', location.host == 'booking.getstarted.smile2impress.com');
app.use(VueGoogleHeatmap, {
    apiKey: "AIzaSyAzRo2GW0DtE6BzX7PuUvplf5ejXg5E1SU"
});
flare.light("jLiYxaFl3P4vFTEp3Y5OtAt7ojluDg4i");
app.use(flareVue);
app.mount("#app");
